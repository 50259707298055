<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="true"
                :showAddNew="false"
                title="Investigation Result Update"
                @onClickCloseButton="onClickToClose"
            />
        </div>

        <div class="my-2 px-2 mb-3">
            <TableHeaderData 
                :leftSideData="tableHeaderData.leftSideData"
                :rightSideData="tableHeaderData.rightSideData"
                :rightColumnWidth="rightColumnWidth"
                v-model:dischargeDate="dischargeDate"
            >
                <template #left-table-slot>
                    <tr>
                        <td class="table-column" :style="`width: ${rightColumnWidth}`">Report Prepared By</td>
                        <td class="table-divider"></td>
                        <td class="table-row dropdown-consultant"> 
                            <AsyncSelect
                                placeholder="Select consutant"
                                v-model="preparedBy"
                                :api-service="fetchServiceList"
                                :additional-query="{ resource_type: 'human_resources', user_role: 'pathologist' }"
                                :format-label="option => option.name"
                            />
                        </td>
                    </tr>
                </template>

                <template #right-table-slot>
                    <tr>
                        <td class="table-column" :style="`width: ${rightColumnWidth}`">Report Approved By</td>
                        <td class="table-divider"></td>
                        <td class="table-row  dropdown-consultant"> 
                            <AsyncSelect
                                placeholder="Select consutant"
                                v-model="approvedBy"
                                :api-service="fetchServiceList"
                                :additional-query="{ resource_type: 'human_resources', user_role: 'pathologist_doctor' }"
                                :format-label="option => option.name"
                            />
                        </td>
                    </tr>
                </template>
            </TableHeaderData>
        </div>

        <InvestigationReport 
            :formattedGenerals="reports.formattedGenerals"
            v-if="reports && reports.formattedGenerals"
        />

        <div class="mb-3 px-2">
          <label for="attachment" class="form-label fw-bold">Attachment</label>
          <BlobFileViewer :attachments="attachments" />
          <FileUpload
              :is-drag-active="true"
              button-text="Upload Documents"
              v-model="attachments"
          >
          </FileUpload>
        </div>

        <div class="row px-2">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 text-center">
                <button :disabled="loading" type="button" @click="submit" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import TableHeaderData from '@/components/molecule/company/hospital/investigation-general/InvestigationGeneralHeader.vue';
import InvestigationReport from '@/components/molecule/company/hospital/investigation-general/InvestigationReport.vue';
import handleHospital from '@/services/modules/hospital'
import handleCompany from "@/services/modules/company";
import { inject, onMounted, reactive, ref } from 'vue';
import { head } from 'lodash';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getCurrentDateTime, dateOfBirthConvertToAge, formatDateToDdMmYy, groupBy } from '@/services/utils/global';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
const { fetchSaleGeneral, updateInvestigationGeneral, saveInvestigationAttachment, fetchServiceList } = handleHospital();
import { replaceDashWithUnderscore } from '@/services/utils/global';

const store = useStore();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const loader = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const tableHeaderData = reactive({
    leftSideData: [],
    rightSideData: [],
});
const rightColumnWidth = '30%';
const dischargeDate = ref(getCurrentDateTime());
const reports = ref(null);
const preparedBy = ref(null);
const approvedBy = ref(null);
const attachments = ref([]);

const submit = async () => {
    const formattedData = formatFormData();

    loading.value = true
    await updateInvestigationGeneral(formattedData).then( async (res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        onClickToClose();
        await saveAttachment();
        return showSuccess(res.message);
    }).catch(err => {
        loading.value = false
    }).finally(() => {
        loading.value = false
    })
}

const saveAttachment = async () => {
    const formData = new FormData();
    let previousFileIds = [];

    formData.append('company_id', route.params.companyId);
    formData.append('report_type', route.query.report_type)
    formData.append('sale_master_id', route.params.saleId);

    for(const key in attachments.value){
        if(typeof attachments.value[key] === 'object' && attachments.value[key].hasOwnProperty('id')) {
            previousFileIds.push(attachments.value[key].id);
            continue;
        }
        formData.append('attachments[]', attachments.value[key]);
    }

    formData.append('previous_file_ids', JSON.stringify(previousFileIds))

    await saveInvestigationAttachment(formData, getQuery());
}

const formatFormData = () => {
    const data = {
        'investigation_results': [],
        'company_id': route.params.companyId,
        'sale_id': route.params.saleId,
        'prepared_by': preparedBy.value ? preparedBy.value.id : '',
        'approved_by': approvedBy.value ? approvedBy.value.id : ''
    }

    for(const key in reports.value.formattedGenerals){
        data.investigation_results = data.investigation_results.concat(reports.value.formattedGenerals[key])
    }

    data.investigation_results = groupBy(data.investigation_results, 'investigation_general_id');

    return data;
}

const onClickToClose = () => {
    const params = Object.assign({}, route.params)
    params.billId = reports.value.id;

    router.push({
        name: 'pending-sample-reports',
        params: params,
        query: route.query
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if(route.params.type) query += `&investigation_type=` + replaceDashWithUnderscore(route.params.type);
    if(route.query.investigation_ids) query += `&investigation_ids=${decodeURIComponent(route.query.investigation_ids)}`;
    if(route.query.report_type) query += `&report_type=` + route.query.report_type;
    return query;
}

const fetchInitialData = async () => {
    const saleId = route.params.saleId;
    const query = getQuery();

    loader.value = true;

    await fetchSaleGeneral(query, saleId)
        .then((res) => {
            if (!res.status) return showError(res.message)
            reports.value = res.data;
            setInvestigationData();
        }).catch((err) => {
            console.log(err)
        });

    loader.value = false
}

const setInvestigationData = () => {
    approvedBy.value = reports.value.approved_by;
    preparedBy.value = reports.value.prepared_by;
    formatLeftSideData(reports.value.contact_profile, reports.value)
    setRightSideData(reports.value);
    attachments.value = reports.value.investigation_attachments;
}

const getAge = (birthday) => {
    if(!birthday) return '';
    const {year} = dateOfBirthConvertToAge(birthday);
    return year;
}

const formatLeftSideData = (patientInfo, reports) => {
    const age = getAge(patientInfo.birthday);

    tableHeaderData.leftSideData = [
        { key: 'Patient ID', value: patientInfo.serial_no },
        { key: 'Patient Name', value: patientInfo.full_name },
        { key: 'Sex & Age', value: patientInfo.gender + ' | ' +   (age ? (age + ' years') : '')},
        { key: 'Patient Phone No', value: patientInfo.mobile_no },
        { key: 'Ref By', value: reports.service_resource.name },
    ];
}

const setRightSideData = (reports) => {
    tableHeaderData.rightSideData = [
        { key: 'Invoice no', value: reports.bill_number },
        { key: 'Invoice date', value: formatDateToDdMmYy(reports.date) },
        { key: 'Investigation SL No', value: ''}
    ]
}

onMounted( async () => {
   await fetchInitialData();
})
</script>

<style scoped>
.dropdown-consultant {
    width: 100% !important;
}
.table-divider{
    padding: 3px 10px;
}
.table-divider:after {
    content: ":";
}
.tr-box-shadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.width-10{
    width: 10%;
}
address{
    margin-bottom: 0 !important;
}
</style>