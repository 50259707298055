<template>
        
    <div class="row">

        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
            <table>
                <tr v-for="item in leftSideData" :key="item.key">
                    <td class="table-column" :style="`width: ${leftColumnWidth}`">{{ item.key }}</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ item.value }}</strong>
                    </td>
                </tr>

                <slot name="left-table-slot"></slot>

            </table>
        </div>

        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
            <table class="right-table">
                <tr v-for="item in rightSideData" :key="item.key">
                    <td class="table-column" :style="`width: ${rightColumnWidth}`">{{ item.key }}</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ item.value }}</strong>
                    </td>
                </tr>

                <slot name="right-table-slot"></slot>

            </table>
        </div>

    </div>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect } from "vue";

const doctor = ref(null);

const props = defineProps({
  leftSideData: {
    type: Array,
    default: [],
  },
  rightSideData: {
    type: Array,
    default: [],
  },
  leftColumnWidth: {
    type: String,
    default: '30%'
  },
  rightColumnWidth: {
    type: String,
    default: '100%'
  }
})
const newDate = ref('');
const emit = defineEmits([
    'update:dischargeDate'
])

const onDataChange = () => {
    emit('update:dischargeDate', newDate.value)
}

watchEffect(() => {
    newDate.value = props.dischargeDate;
});
</script>

<style scoped>
.table-divider{
    padding: 3px 10px;
}
.table-divider:after {
    content: ":";
}
</style>